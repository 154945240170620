import React from 'react';
import {Link} from 'gatsby'
export default ({children,pathData})=>(
    <div id="header">
    <div className="header-container">
        { 
            children ?
            children
            :  <>
                        <h1>{pathData.title}</h1>
                        <div className="breadcrumbs">
                            <span className="base">
                                <Link to="/">Home</Link>
                            </span>
                            <span className="divider"/>
                            <span className={pathData.parentPath ? 'parent' :'current'}>
                                <Link to={pathData.parentPath ? pathData.parentPath.link : pathData.link}>
                                    {pathData.parentPath ? pathData.parentPath.name : pathData.name}
                                </Link>
                            </span>
                            {
                                pathData.parentPath && <span className="divider"/>
                            }
                            {
                                pathData.parentPath &&  
                                <span className="current">
                                    <Link to={pathData.link}>
                                        {pathData.name}
                                    </Link>
                                </span>
                            }
                        </div>
                </>
           
        }
       

    </div>
</div>
)