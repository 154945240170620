import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMapMarker,faPhone,faEnvelope,faGlobe} from '@fortawesome/free-solid-svg-icons';
export default ({contact})=>(
    <div id="contact" className="animated in-view" data-animatequeue="fadeIn">
        <h2>Contact Us!</h2>
        <p><strong>We would love to hear from you</strong></p>
        <ul>
            {
                contact.address && 
                <li><FontAwesomeIcon icon={faMapMarker}/> Address: {contact.address}</li>
            }
            {
                contact.phone && 
                <li><FontAwesomeIcon icon={faPhone}/> Phone: {contact.phone}</li>
            }
            {
                contact.email &&
                <li><FontAwesomeIcon icon={faEnvelope}/> Email: <a href={`mailto:${contact.email}`} target="_blank">{contact.email}</a></li>
            }
           {
               contact.website &&
               <li><FontAwesomeIcon icon={faGlobe}/> Website: {contact.website}</li>
           }
        </ul>
    </div>
)