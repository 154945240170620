import React from 'react';
import Contact from '../components/Contact'
export default ({children,contact})=>(
    <footer>
        <span className="overlay"/>
        {
            children ? 
            children
            : <Contact contact={contact} />
        }
    </footer>
)