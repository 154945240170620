import React from 'react';
import moment from 'moment';
export function AnimateQueue(){
    let count = 0;
    function animate(elements){
      return new Promise((resolve)=>{
        const animateQ = [...elements]
        //set up queue on all elements
        animateQ.map((e,i)=>{
          //show an element
          const show = (element) => {
            //check if element is in view before showing
            element.style.visibility = 'visible';
            element.classList.add(element.dataset.animatequeue);
          }
          //check if there is other elements after this one in the queue, if not, resolve the promise to end this iteration
          const handleNext = (el,q,index) =>{
            if (q[index+1]){
              show(q[index+1])
            } else {
              resolve()
            }
            el.classList.remove('animated')
            el.removeEventListener('animationend',handleNext)
          }
          e.addEventListener('animationend',()=>handleNext(e,animateQ,i));
          //check if this is the first element to start the queue
          if(i===0){
            //check if element is visible before starting off the queue
            const intersectionCallback = (entries,observer) => {
              entries.forEach(entry=>{
                if (entry.isIntersecting) {
                  show(entry.target)
                  observer.disconnect()
                }
              })
            }
            let animationObserver = new IntersectionObserver(intersectionCallback)
            animationObserver.observe(e)
          }
        })
      })
    }
    (function animateIterator(){
      const animated = document.querySelectorAll(`.animated.q${count}`);
      if (animated.length > 0){
        animate(animated)
          .then(()=>{
            count +=1;
            animateIterator();
          })
      } 
    })()
} 
export function animateInView(){
  //function that animates regardless of place in queue
  const animateElements = document.querySelectorAll('.animated.in-view');
  if(animateElements.length > 0){
    const elements = [...animateElements];
    const show = (element) =>{
      element.style.visibility = 'visible';
      element.classList.add(element.dataset.animatequeue);
    }
    const intersectionCallback = (entries,observer) =>{
      entries.forEach(entry=>{
        if(entry.isIntersecting){
          show(entry.target);
          observer.unobserve(entry.target);
        }
      })
    }
    let animationObserver = new IntersectionObserver(intersectionCallback);
    elements.forEach((element)=>{
      animationObserver.observe(element)
    })
  
  }
}
export function navStyles(){
  const nav = document.querySelector('#mainNav');
  const header = document.querySelector('#header');
  const navCallback = (entries,observer) =>{
    entries.forEach(entry=>{
      if (entry.isIntersecting){
        nav.classList.remove('over-content')
      } else {
        nav.classList.add('over-content');
      }
      
    })
  }
  let navObserver = new IntersectionObserver(navCallback);
  navObserver.observe(header);


}
export function checkCookie(name,value){
    const regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
    return value ? (document.cookie.replace(regex,"$1") === value) : (document.cookie.replace(regex,"$1") || null);
  }

export function setCookie(name,value,expires) {
    const cookieDate = expires ? '0' : new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toUTCString();
    document.cookie = name + "=" + (value ? value : 1) + ";expires=" + cookieDate + ";path=/;";
  }

export function deleteCookie(name){
    document.cookie = name + "=;expires=Fri, 31 Dec 1999 23:59:59 GMT;path=/;"; 
}
export function money(input){
    if(input){
      return `$${parseInt(input) / 100}`
    }
}
export function dateFormat(input){
  if(input){
    const toNum = parseInt(input)
    if (toNum){
      input = moment.unix(toNum)
    }
    return moment(input).format('MMMM DD, YYYY')
  }
}
export function checkStorage(name,value){
  return new Promise((resolve,reject)=>{
    if (!value){
       resolve(window.localStorage.getItem(name));
    } else {
      resolve(value === window.localStorage.getItem(name)); 
    }
  })
 
}
export function setStorage(name,value) {
  return new Promise((resolve,reject)=>{
    window.localStorage.setItem(name,value);
    resolve();
  })
}
export function deleteStorage(name){
  window.localStorage.deleteStorage(name);
}
