import React,{useEffect,useState} from 'react'
import Helmet from 'react-helmet'
import {StaticQuery,graphql,Link} from 'gatsby'
import Header from './Header'
import Footer from './Footer'
import '../utils/reset.css'
import '../utils/animate.css'
import '../utils/main.scss'
import logo from '../images/logo_lg.png'
import logoSM from '../images/logo.png'
import {AnimateQueue,animateInView,navStyles} from '../utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars,faWindowClose} from '@fortawesome/free-solid-svg-icons';
export default ({children, location}) => {
    const [mobileNav,setMobileNav] = useState(false);
    const menuToggle = (event) =>{
        event.preventDefault();
        setMobileNav(!mobileNav)
    }
    useEffect(()=>{
        AnimateQueue();
        animateInView();
        navStyles();
    },[])
    return (
        <StaticQuery
        query={
            graphql`
            query SiteTitleQuery{
                site{
                    siteMetadata {
                        title
                        description
                        address
                        phone
                        email
                        website
                        siteNav {
                            title
                            name
                            link
                            subnav {
                                title
                                name
                                link
                            }
                        }
                    }
                }
            
            }
            `}
            render={data=> {
            const {address,phone,email,website} = data.site.siteMetadata;
            let pathData = data.site.siteMetadata.siteNav.filter(nav=>nav.link === '/' + location.pathname.split('/')[1])[0] || {title:'Not Found',link:'/404'};
            const hasSubNav = pathData?.subnav ? true : false;
            if (hasSubNav && pathData.link !== location.pathname){
                pathData = Object.assign({},pathData.subnav.filter(subnav=>subnav.link ===  location.pathname)[0],{subnav:pathData.subnav},{parentPath:pathData});
            }
            
        return (
                <>
                    <Helmet 
                        title={`${pathData.title} - ${data.site.siteMetadata.title}`}
                        meta={[{name:'description',content:data.site.siteMetadata.description}]}
                        >
                        <html lang="en"/>
                    </Helmet>
                    <nav id="mainNav">
                            <div className="nav-logo">
                                <Link to="/">
                                    <img src={logo} className="lg" alt={data.site.siteMetadata.title}/>
                                    <img src={logoSM} className="sm" alt={data.site.siteMetadata.title}/>
                                </Link>
                            </div>
                            <div className="mobile-nav-toggle">
                                <a href="#" onClick={menuToggle}><FontAwesomeIcon icon={mobileNav ? faWindowClose: faBars}/></a>   
                            </div>
                            <div className={`main-nav ${mobileNav ? 'expanded' : ''}`} >
                                {
                                    data.site.siteMetadata.siteNav.map(link=>{
                                        if(link.link !=="/"){
                                            return (
                                                <div key={link.link} className={link.subnav && 'dropdown-toggle'}>
                                                    <Link to={link.link} className={location.pathname === link.link ? 'active' : ''}>
                                                        {link.name}	
                                                    </Link>
                                                    {
                                                        link.subnav && <ul className="dropdown">
                                                            {
                                                                link.subnav.map((sublink)=>(
                                                                <li key={sublink.link} className={location.pathname === sublink.link ? 'active':''}>
                                                                    <Link to={sublink.link}>
                                                                        {sublink.name} 
                                                                    </Link>
                                                                </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </div>   
                                            )
                                        }
                                    })
                                }
                            </div>
                        </nav>
                    <header>
                       
                        {
                            pathData.link !== "/" && 
                            <Header pathData={pathData}/>
                        }
                    
                    
                    </header>
                    
                
                    <div id="content-wrap">
                        <div className={`${hasSubNav ? 'withSubNav':''}`}>
                            {children}
                        </div>
                        {
                            hasSubNav && 
                            <div className="side-nav">
                                <ul>
                                {
                                        pathData.subnav.map(sub=>(
                                            <li key={`sub_${sub.link}`} className={location.pathname === sub.link ? 'active':''}>
                                                <Link to={sub.link}>
                                                    {sub.name} 
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                                
                            </div>
                        }
                    
                    </div>
                <Footer contact={{address,email,phone,website}}/>
                </>
            )

            }}
            />
    )
}